import { useParams, useSearchParams } from "react-router-dom"
import { Logo } from "../Icons/Logo"
import styles from './RedirectBlock.module.scss'
import { Props, RedirectBlockType } from "./types"
import { useEffect } from "react"
export const RedirectBlock = (props:Props) => {
    const {id} = useParams()
    const [searchParams, setSearchParams]  = useSearchParams()
    const userLang = navigator.language
    useEffect(()=>{
        const url = props.type === RedirectBlockType.users? 'users' : 'wish'
        if(url === 'users'){
            window.location.href = `com.getwish://${url}?id=${searchParams.get("id")}`
        }else{
            window.location.href = `com.getwish://${url}?id=${id}`
        }
        
    })
    return <div className={styles.root}>
        <Logo/>
        <a 
            className={styles.root__button} 
            href="https://apps.apple.com/app/getwish-%D1%81%D0%BE%D0%B7%D0%B4%D0%B0%D0%B9-%D1%81%D0%B2%D0%BE%D0%B9-%D0%B2%D0%B8%D1%88%D0%BB%D0%B8%D1%81%D1%82/id6447369509">
                {userLang === 'ru-RU'? 'Скачать приложение': 'Download Application'}
        </a>
    </div>
}
