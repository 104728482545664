import React from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import './styles/style.css'
import { RedirectBlock } from './components/RedirectBlock/RedirectBlock';
import { Container } from './components/Container/Container';
import { RedirectBlockType } from './components/RedirectBlock/types';

function App() {
  const router = createBrowserRouter([
    {
      path: "/users/:id",
      element: <RedirectBlock type={RedirectBlockType.users}/>,
    },
    {
      path:"/wish/:id",
      element: <RedirectBlock type={RedirectBlockType.wish}/>
    },
    {
      path:'*',
      element:<></>
    }
  ]);
  return (
    <Container>
      <RouterProvider router={router} />
    </Container>
      
  );
}

export default App;
