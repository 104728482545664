export const Logo = ()=>{
    return (
        <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_408_5872)">
<rect x="20" y="20" width="120" height="120" rx="28" fill="url(#paint0_linear_408_5872)"/>
<path d="M121.409 56.0045C121.395 49.2338 118.148 44.7289 112.206 43.2381C106.264 41.7473 97.9852 43.3605 88.7454 47.8097C79.5055 52.2588 69.8607 59.276 61.4132 67.6954C52.9658 76.1148 46.2244 85.4295 42.309 94.092C38.3937 102.754 37.5401 110.243 39.8901 115.314C42.2401 120.385 47.6521 122.732 55.2272 121.966C62.6794 121.213 71.7837 117.491 81.0716 111.407C81.356 111.221 81.5196 110.896 81.5015 110.556L81.1915 104.746C81.1519 104.005 80.3196 103.585 79.691 103.981C73.3803 107.951 67.2389 110.377 62.1623 110.89C56.708 111.442 52.811 109.751 51.1189 106.1C49.4268 102.449 50.0415 97.0569 52.8607 90.8195C55.6799 84.5822 60.534 77.8752 66.6165 71.8128C72.6991 65.7505 79.6438 60.6978 86.2969 57.4943C92.95 54.2907 99.0539 51.694 104.573 53.0479C109.759 54.3203 112.869 58.3251 112.879 63.2003C112.888 67.4397 108.778 75.7101 104.903 82.0816C104.524 82.7037 105.231 83.3806 105.808 82.9355C115.602 75.3687 121.409 63.9555 121.409 56.0045Z" fill="url(#paint1_linear_408_5872)"/>
<path d="M121.409 56.0045C121.395 49.2338 118.148 44.7289 112.206 43.2381C106.264 41.7473 97.9852 43.3605 88.7454 47.8097C79.5055 52.2588 69.8607 59.276 61.4132 67.6954C52.9658 76.1148 46.2244 85.4295 42.309 94.092C38.3937 102.754 37.5401 110.243 39.8901 115.314C42.2401 120.385 47.6521 122.732 55.2272 121.966C62.6794 121.213 71.7837 117.491 81.0716 111.407C81.356 111.221 81.5196 110.896 81.5015 110.556L81.1915 104.746C81.1519 104.005 80.3196 103.585 79.691 103.981C73.3803 107.951 67.2389 110.377 62.1623 110.89C56.708 111.442 52.811 109.751 51.1189 106.1C49.4268 102.449 50.0415 97.0569 52.8607 90.8195C55.6799 84.5822 60.534 77.8752 66.6165 71.8128C72.6991 65.7505 79.6438 60.6978 86.2969 57.4943C92.95 54.2907 99.0539 51.694 104.573 53.0479C109.759 54.3203 112.869 58.3251 112.879 63.2003C112.888 67.4397 108.778 75.7101 104.903 82.0816C104.524 82.7037 105.231 83.3806 105.808 82.9355C115.602 75.3687 121.409 63.9555 121.409 56.0045Z" fill="#FFCF00"/>
<mask id="mask0_408_5872" maskUnits="userSpaceOnUse" x="38" y="42" width="84" height="81">
<path d="M121.409 56.0045C121.395 49.2338 118.148 44.7289 112.206 43.2381C106.264 41.7473 97.9852 43.3605 88.7454 47.8097C79.5055 52.2588 69.8607 59.276 61.4132 67.6954C52.9658 76.1148 46.2244 85.4295 42.309 94.092C38.3937 102.754 37.5401 110.243 39.8901 115.314C42.2401 120.385 47.6521 122.732 55.2272 121.966C62.6794 121.213 71.7837 117.491 81.0716 111.407C81.356 111.221 81.5196 110.896 81.5015 110.556L81.1915 104.746C81.1519 104.005 80.3196 103.585 79.691 103.981C73.3803 107.951 67.2389 110.377 62.1623 110.89C56.708 111.442 52.811 109.751 51.1189 106.1C49.4268 102.449 50.0415 97.0569 52.8607 90.8195C55.6799 84.5822 60.534 77.8752 66.6165 71.8128C72.6991 65.7505 79.6438 60.6978 86.2969 57.4943C92.95 54.2907 99.0539 51.694 104.573 53.0479C109.759 54.3203 112.869 58.3251 112.879 63.2003C112.888 67.4397 108.778 75.7101 104.903 82.0816C104.524 82.7037 105.231 83.3806 105.808 82.9355C115.602 75.3687 121.409 63.9555 121.409 56.0045Z" fill="#FFA800"/>
</mask>
<g mask="url(#mask0_408_5872)">
<g filter="url(#filter1_f_408_5872)">
<circle cx="111.439" cy="84.5603" r="15.12" fill="#FFE8AD"/>
</g>
<g filter="url(#filter2_f_408_5872)">
<ellipse cx="65.4282" cy="69.2687" rx="32.2951" ry="9.54925" transform="rotate(-45 65.4282 69.2687)" fill="#FFE8AD"/>
</g>
<g filter="url(#filter3_d_408_5872)">
<path d="M100.208 92.5174C101.126 92.1278 101.956 93.2234 101.332 94.0016L91.42 106.365C91.2121 106.624 91.1531 106.973 91.264 107.286L96.1822 121.176C96.4947 122.059 95.4883 122.821 94.7236 122.281L82.6862 113.782C82.4148 113.59 82.0635 113.552 81.7575 113.682L67.1703 119.872C66.2521 120.261 65.4224 119.166 66.0463 118.388L75.9582 106.024C76.1661 105.765 76.2251 105.417 76.1141 105.103L71.1959 91.2129C70.8835 90.3304 71.8899 89.5683 72.6546 90.1083L84.6919 98.6075C84.9634 98.7991 85.3147 98.8368 85.6206 98.707L100.208 92.5174Z" fill="#FFE600"/>
</g>
</g>
<path d="M100.207 92.5174C101.125 92.1278 101.955 93.2234 101.331 94.0016L91.419 106.365C91.2111 106.624 91.1521 106.973 91.263 107.286L96.1812 121.176C96.4937 122.059 95.4873 122.821 94.7226 122.281L82.6853 113.782C82.4138 113.59 82.0625 113.552 81.7566 113.682L67.1693 119.872C66.2511 120.261 65.4214 119.166 66.0453 118.388L75.9572 106.024C76.1651 105.765 76.2241 105.417 76.1132 105.103L71.195 91.2129C70.8825 90.3304 71.8889 89.5683 72.6536 90.1083L84.6909 98.6075C84.9624 98.7991 85.3137 98.8368 85.6196 98.707L100.207 92.5174Z" fill="url(#paint2_linear_408_5872)"/>
<path d="M112.572 103.161C113.031 102.966 113.446 103.514 113.134 103.903L109.929 107.902C109.825 108.031 109.795 108.205 109.851 108.362L111.464 112.918C111.62 113.359 111.117 113.74 110.734 113.47L106.786 110.683C106.651 110.587 106.475 110.568 106.322 110.633L101.604 112.635C101.145 112.829 100.73 112.282 101.042 111.892L104.248 107.894C104.352 107.764 104.381 107.59 104.326 107.434L102.713 102.878C102.557 102.437 103.06 102.056 103.442 102.326L107.39 105.113C107.526 105.209 107.702 105.228 107.854 105.163L112.572 103.161Z" fill="#FFD600"/>
<path d="M79.3543 36.6393C79.8134 36.4445 80.2283 36.9923 79.9163 37.3814L76.7107 41.3798C76.6068 41.5094 76.5773 41.6836 76.6328 41.8402L78.2458 46.396C78.402 46.8372 77.8989 47.2183 77.5165 46.9483L73.5685 44.1607C73.4328 44.0649 73.2572 44.0461 73.1042 44.111L68.3866 46.1127C67.9275 46.3076 67.5126 45.7598 67.8246 45.3706L71.0302 41.3722C71.1341 41.2426 71.1636 41.0684 71.1081 40.9118L69.4951 36.3561C69.3388 35.9148 69.842 35.5338 70.2244 35.8037L74.1724 38.5913C74.3081 38.6871 74.4837 38.7059 74.6367 38.641L79.3543 36.6393Z" fill="#FFD600"/>
<path d="M90.1506 35.5819C90.426 35.4651 90.6749 35.7937 90.4878 36.0272L88.8223 38.1046C88.76 38.1823 88.7423 38.2869 88.7755 38.3808L89.6187 40.7622C89.7124 41.027 89.4105 41.2556 89.1811 41.0936L87.1174 39.6365C87.036 39.579 86.9306 39.5677 86.8388 39.6066L84.3878 40.6467C84.1123 40.7635 83.8634 40.4349 84.0506 40.2014L85.716 38.124C85.7784 38.0462 85.7961 37.9417 85.7628 37.8478L84.9197 35.4664C84.8259 35.2016 85.1278 34.973 85.3573 35.135L87.4209 36.5921C87.5024 36.6496 87.6078 36.6609 87.6995 36.6219L90.1506 35.5819Z" fill="#FFD600"/>
<path d="M39.4405 37.3074C39.5323 37.2685 39.6152 37.378 39.5529 37.4558L38.4816 38.7921C38.4608 38.818 38.4549 38.8528 38.466 38.8841L38.9965 40.3825C39.0278 40.4707 38.9271 40.5469 38.8507 40.493L37.5522 39.5762C37.5251 39.557 37.4899 39.5532 37.4593 39.5662L35.8828 40.2352C35.791 40.2741 35.708 40.1646 35.7704 40.0867L36.8416 38.7505C36.8624 38.7246 36.8683 38.6898 36.8572 38.6584L36.3267 37.1601C36.2955 37.0719 36.3961 36.9956 36.4726 37.0496L37.771 37.9664C37.7982 37.9856 37.8333 37.9894 37.8639 37.9764L39.4405 37.3074Z" fill="#FFE249"/>
<path d="M29.6035 59.9715C29.6954 59.9325 29.7783 60.0421 29.7159 60.1199L28.6447 61.4561C28.6239 61.482 28.618 61.5169 28.6291 61.5482L29.1596 63.0466C29.1909 63.1348 29.0902 63.211 29.0138 63.157L27.7153 62.2402C27.6882 62.221 27.653 62.2173 27.6224 62.2303L26.0459 62.8992C25.9541 62.9382 25.8711 62.8286 25.9335 62.7508L27.0047 61.4146C27.0255 61.3887 27.0314 61.3538 27.0203 61.3225L26.4898 59.8242C26.4586 59.7359 26.5592 59.6597 26.6357 59.7137L27.9341 60.6305C27.9613 60.6497 27.9964 60.6534 28.027 60.6404L29.6035 59.9715Z" fill="#FFE249"/>
<path d="M37.3273 63.9804C37.4191 63.9414 37.5021 64.051 37.4397 64.1288L35.8138 66.1568C35.793 66.1827 35.7871 66.2176 35.7982 66.2489L36.5968 68.5044C36.6281 68.5926 36.5274 68.6688 36.451 68.6148L34.4964 67.2348C34.4693 67.2156 34.4341 67.2118 34.4035 67.2248L32.0107 68.2401C31.9189 68.2791 31.836 68.1695 31.8983 68.0917L33.5242 66.0637C33.545 66.0378 33.5509 66.0029 33.5398 65.9716L32.7412 63.7161C32.71 63.6279 32.8106 63.5517 32.8871 63.6056L34.8417 64.9857C34.8688 65.0049 34.9039 65.0086 34.9345 64.9957L37.3273 63.9804Z" fill="#FFE249"/>
<path d="M30.2979 98.019C30.3897 97.98 30.4727 98.0896 30.4103 98.1674L29.339 99.5036C29.3182 99.5295 29.3123 99.5644 29.3234 99.5957L29.854 101.094C29.8852 101.182 29.7846 101.258 29.7081 101.204L28.4096 100.288C28.3825 100.269 28.3474 100.265 28.3168 100.278L26.7402 100.947C26.6484 100.986 26.5654 100.876 26.6278 100.798L27.6991 99.4621C27.7199 99.4362 27.7258 99.4013 27.7147 99.37L27.1841 97.8716C27.1529 97.7834 27.2535 97.7072 27.33 97.7612L28.6285 98.678C28.6556 98.6971 28.6907 98.7009 28.7213 98.6879L30.2979 98.019Z" fill="#FFE249"/>
<path d="M78.523 127.764C78.6148 127.726 78.6978 127.835 78.6354 127.913L77.169 129.742C77.1482 129.768 77.1423 129.803 77.1534 129.834L77.8749 131.872C77.9062 131.96 77.8056 132.036 77.7291 131.982L75.9632 130.735C75.936 130.716 75.9009 130.713 75.8703 130.725L73.7123 131.641C73.6204 131.68 73.5375 131.571 73.5999 131.493L75.0662 129.664C75.087 129.638 75.0929 129.603 75.0818 129.572L74.3603 127.534C74.3291 127.446 74.4297 127.369 74.5062 127.423L76.2721 128.67C76.2992 128.689 76.3344 128.693 76.365 128.68L78.523 127.764Z" fill="#FFE249"/>
<path d="M126.875 89.4444C126.967 89.4054 127.05 89.515 126.987 89.5928L125.916 90.929C125.895 90.9549 125.889 90.9898 125.901 91.0211L126.431 92.5195C126.462 92.6077 126.362 92.6839 126.285 92.6299L124.987 91.7131C124.96 91.6939 124.925 91.6902 124.894 91.7032L123.317 92.3721C123.226 92.4111 123.143 92.3015 123.205 92.2237L124.276 90.8875C124.297 90.8616 124.303 90.8267 124.292 90.7954L123.761 89.2971C123.73 89.2088 123.831 89.1326 123.907 89.1866L125.206 90.1034C125.233 90.1226 125.268 90.1263 125.298 90.1133L126.875 89.4444Z" fill="#FFE249"/>
<path d="M106.593 115.96C106.685 115.921 106.768 116.031 106.705 116.109L105.634 117.445C105.613 117.471 105.607 117.506 105.618 117.537L106.149 119.035C106.18 119.124 106.079 119.2 106.003 119.146L104.705 118.229C104.677 118.21 104.642 118.206 104.612 118.219L103.035 118.888C102.943 118.927 102.86 118.818 102.923 118.74L103.994 117.403C104.015 117.378 104.021 117.343 104.01 117.311L103.479 115.813C103.448 115.725 103.548 115.649 103.625 115.703L104.923 116.619C104.951 116.639 104.986 116.642 105.016 116.629L106.593 115.96Z" fill="#FFE249"/>
<path d="M132.633 43.42C132.725 43.381 132.808 43.4906 132.745 43.5684L131.674 44.9046C131.653 44.9305 131.647 44.9654 131.658 44.9967L132.189 46.495C132.22 46.5833 132.12 46.6595 132.043 46.6055L130.745 45.6887C130.717 45.6695 130.682 45.6658 130.652 45.6788L129.075 46.3477C128.983 46.3867 128.9 46.2771 128.963 46.1993L130.034 44.8631C130.055 44.8372 130.061 44.8023 130.05 44.771L129.519 43.2726C129.488 43.1844 129.588 43.1082 129.665 43.1622L130.963 44.079C130.991 44.0981 131.026 44.1019 131.056 44.0889L132.633 43.42Z" fill="#FFE249"/>
<path d="M117.419 26.6609C117.511 26.622 117.594 26.7315 117.531 26.8094L116.46 28.1456C116.439 28.1715 116.433 28.2063 116.445 28.2377L116.975 29.736C117.006 29.8243 116.906 29.9005 116.829 29.8465L115.531 28.9297C115.504 28.9105 115.468 28.9067 115.438 28.9197L113.861 29.5887C113.769 29.6276 113.687 29.5181 113.749 29.4403L114.82 28.104C114.841 28.0781 114.847 28.0433 114.836 28.012L114.305 26.5136C114.274 26.4254 114.375 26.3492 114.451 26.4031L115.75 27.3199C115.777 27.3391 115.812 27.3429 115.842 27.3299L117.419 26.6609Z" fill="#FFE249"/>
<path d="M135.915 49.5395C136.006 49.5005 136.089 49.6101 136.027 49.6879L134.573 51.5022C134.552 51.5281 134.546 51.563 134.557 51.5943L135.273 53.6159C135.304 53.7041 135.203 53.7803 135.127 53.7263L133.375 52.4894C133.348 52.4702 133.313 52.4665 133.282 52.4794L131.141 53.3877C131.05 53.4267 130.967 53.3171 131.029 53.2393L132.484 51.425C132.504 51.3991 132.51 51.3643 132.499 51.3329L131.783 49.3114C131.752 49.2231 131.853 49.1469 131.929 49.2009L133.681 50.4379C133.708 50.457 133.743 50.4608 133.774 50.4478L135.915 49.5395Z" fill="#FFE249"/>
<path d="M126.875 125.48C126.967 125.441 127.05 125.551 126.987 125.628L125.916 126.965C125.895 126.99 125.889 127.025 125.901 127.057L126.431 128.555C126.462 128.643 126.362 128.719 126.285 128.665L124.987 127.749C124.96 127.729 124.925 127.726 124.894 127.739L123.317 128.408C123.226 128.447 123.143 128.337 123.205 128.259L124.276 126.923C124.297 126.897 124.303 126.862 124.292 126.831L123.761 125.333C123.73 125.244 123.831 125.168 123.907 125.222L125.206 126.139C125.233 126.158 125.268 126.162 125.298 126.149L126.875 125.48Z" fill="#FFE249"/>
<path d="M118.646 54.4904C119.238 45.8858 109.812 42.4646 100.114 46.4898C99.9051 46.5763 100.003 46.8768 100.225 46.8407C111.158 45.0673 116.996 49.7104 118.175 54.4934C118.23 54.7164 118.631 54.7196 118.646 54.4904Z" fill="url(#paint3_linear_408_5872)"/>
<path d="M40.5763 104.603C37.9156 114.703 46.5415 122.674 56.8701 118.87C57.066 118.798 57.011 118.515 56.8024 118.508C45.5387 118.138 40.9029 111.103 40.9788 104.678C40.9816 104.448 40.6348 104.381 40.5763 104.603Z" fill="url(#paint4_linear_408_5872)"/>
<path d="M54.5698 78.0913C59.0855 70.7817 72.1391 58.4163 84.7908 52.149C71.9808 62.0282 64.9196 67.1953 54.5698 78.0913Z" fill="url(#paint5_linear_408_5872)"/>
</g>
<defs>
<filter id="filter0_d_408_5872" x="0" y="0" width="160" height="160" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="10"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.39 0 0 0 0 0.975 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_408_5872"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_408_5872" result="shape"/>
</filter>
<filter id="filter1_f_408_5872" x="73.7593" y="46.8803" width="75.3602" height="75.36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="11.28" result="effect1_foregroundBlur_408_5872"/>
</filter>
<filter id="filter2_f_408_5872" x="19.0484" y="22.8891" width="92.7597" height="92.7593" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="11.28" result="effect1_foregroundBlur_408_5872"/>
</filter>
<filter id="filter3_d_408_5872" x="49.5091" y="80.328" width="62.5997" height="59.4133" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-2.88" dy="3.84"/>
<feGaussianBlur stdDeviation="6.72"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
<feBlend mode="overlay" in2="BackgroundImageFix" result="effect1_dropShadow_408_5872"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_408_5872" result="shape"/>
</filter>
<linearGradient id="paint0_linear_408_5872" x1="-2.32" y1="4.16" x2="140" y2="140" gradientUnits="userSpaceOnUse">
<stop stop-color="#FB26FF"/>
<stop offset="1" stop-color="#8424FF"/>
</linearGradient>
<linearGradient id="paint1_linear_408_5872" x1="55.5195" y1="119.36" x2="75.1995" y2="111.92" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFE600"/>
<stop offset="1" stop-color="#FFB800"/>
</linearGradient>
<linearGradient id="paint2_linear_408_5872" x1="120.433" y1="101.784" x2="68.1772" y2="98.4084" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFB800"/>
<stop offset="1" stop-color="#FFE600"/>
</linearGradient>
<linearGradient id="paint3_linear_408_5872" x1="100.88" y1="46.88" x2="117.92" y2="54.8" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFF2AB" stop-opacity="0.1"/>
<stop stop-color="#FFE975" stop-opacity="0.15"/>
<stop offset="0.566386" stop-color="#FFF2AB"/>
<stop offset="1" stop-color="#FFE975" stop-opacity="0.15"/>
</linearGradient>
<linearGradient id="paint4_linear_408_5872" x1="52.4407" y1="119.821" x2="41.057" y2="103.37" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFE975" stop-opacity="0.15"/>
<stop offset="0.5625" stop-color="#FFF2AB"/>
<stop offset="1" stop-color="#FFE975" stop-opacity="0.15"/>
</linearGradient>
<linearGradient id="paint5_linear_408_5872" x1="54.5701" y1="78.4563" x2="85.1922" y2="54.891" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFE975" stop-opacity="0.15"/>
<stop offset="0.478748" stop-color="#FFF2AB"/>
<stop offset="1" stop-color="#FFE975" stop-opacity="0.15"/>
</linearGradient>
</defs>
</svg>

    )
}